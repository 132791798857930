<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="col-sm-12">
            <b class="required">Tiêu đề</b>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <el-input
                placeholder="Tiếng việt"
                v-model="form.title_vi"
                :class="errors.has('title_vi')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="title_vi"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('title_vi')">{{ errors.first('title_vi') }}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <el-input
                placeholder="English"
                v-model="form.title_en"
                :class="errors.has('title_en')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="title_en"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('title_en')">{{ errors.first('title_en') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b class="required">Link</b>
            </div>
            <div class="col-sm-12">
              <el-input
                placeholder="Link"
                v-model="form.slug"
                :class="errors.has('slug')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="slug"
                data-vv-as="Link"
              ></el-input>
              <span class="text-danger" v-if="errors.has('slug')">{{ errors.first('slug') }}</span>
              </el-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b>Mô tả</b>
            </div>
            <div class="col-sm-12">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Mô tả"
                v-model="form.description_vi"
              >
              </el-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b>Mô tả (en)</b>
            </div>
            <div class="col-sm-12">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Mô tả (en)"
                v-model="form.description_en">
              </el-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b>Hiển thị ở trang chủ</b>
            </div>
            <div class="col-sm-12">
              <el-switch
                v-model="form.display_homepage"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="col-sm-12">
            <b>Hình ảnh</b>
          </div>
          <div class="col-sm-12">
            <my-image
              v-model="form.image"
            >
            </my-image>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Input, Button, Message, Switch } from 'element-ui';
  import MyImage from 'src/components/UIComponents/Image'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      ElSwitch: Switch,
      Message,
      MyImage
    },

    computed: {
      form: {
        get() {
          return this.$store.state.themeDetail;
        },
        set(value) {}
      },
    },

    mounted() {
      let id = this.$route.params.id;
      this.$store.dispatch('fetchThemeDetail', id);
      this.$store.dispatch('setPageTitle', 'Cập nhật chủ đề');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllThemes'});
      },
      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            self.$store.dispatch('updateTheme', self.form).then(res => {
              if (res.success) {
                Message({
                  message: 'Cập nhật thành công',
                  type: 'success'
                });
              } else {
                if (res.errors) {
                  for (let err in res.errors) {
                    Message({
                      message: res.errors[err][0],
                      type: 'error'
                    });
                  }
                } else {
                  Message({
                    message: res.message,
                    type: 'error'
                  });
                }
              }
            }, error => {
              console.error(error);
            });
          }
        })
      }
    },
    watch: {
      'form.title_vi': function(newVal, oldVal) {
        if (newVal) {
          this.form.slug = this.$util.createHandle(newVal);
        }
      },
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>


<style lang="scss">
.row {
  margin: 0 0 15px !important;
  b {
    display: block;
    margin-bottom: 5px;
  }
}
</style>
